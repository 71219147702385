.container{
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.greyBox{
    z-index: 1;
    background-color: rgb(211, 211, 211);
    height: 150px;
    width: 100%;
    position: absolute;

}

.profilePicture{
    object-fit: cover;
    z-index: 2;
    margin-top: 90px;
    border-radius: 100%;
    border: 2px solid rgb(236, 236, 236);
    height: 100px;
    width: 100px;
    margin-bottom: 0px;
}

.backgroundPic{
    height:100%;
    width: 100%;
    object-fit: cover;

}

.name{
    margin:5px;
    font-size: 30px;
    font-weight:bold;

}

.addressWrapper{
    display: flex;
    border: 3px solid rgb(211, 211, 211);
    border-radius: 8px;
    align-items: center;
    gap:3px;
    height: 22px;
}
.cryptoSign{
    height: 20px;
}

.address{
    font-size: 15px;

}

.friend_symbol{
    height: 25px;
    z-index: 200;
    opacity: 50%;
}
.friend_symbol:hover{
    opacity: 100%;
}

.nameWrapper{
    gap:10px;
    display: flex;
    align-items: center;
}



.buttonPositon{
    position:absolute;
    top: 260px;
    right: 40px;
    z-index: 20;
}

.followWrapper{
    margin-top: 40px;
    display: flex;
    gap:8px;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
}
.followWrapper:hover{
    opacity: 1;
}
.herz{
    height: 15px;
}




@media(max-width: 600px){

    .buttonPositon{
        right: 10px;
    }
}