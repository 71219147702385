.container{

    max-width: 400px;
    height: auto;
    border-radius: 5px;
    margin: 7px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(0, 110, 253, 0.151);
}

.top{
    width: calc(100% - 10px);
    font-size: 12px;
    opacity: 0.7;
    padding-left: 5px;
    padding-right: 5px;
}

.bottom{
    width: 100%;
    font-size: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;

    white-space: normal;   /* Erlaubt Umbruch */
  overflow-wrap: break-word; /* Lange Wörter werden umgebrochen */
}