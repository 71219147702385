

.integration{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    overflow-y: auto;
    flex: 1;   


}

.box{

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 90%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;

}


.action{
    width:calc(100% - 24px);
    text-align: center;
    padding:12px;
}





.approve{
    width: calc(100% - 20px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;

}

.details{
    width:100%;
    height: 200px;

}

.data{
    padding:5px;
    font-size: 16px;
    display: flex;
    width: calc(100% - 10px);
    justify-content: space-between;
}


.pwWrapper{
    width:100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}