
.integration{
    display: inline;
    overflow-y: scroll;

    height: 350px;
    width: 100%;


}

::-webkit-scrollbar {
    display: none;
}

