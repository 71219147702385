.container{


    width: 100%;
    height: 60px;
    display:flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid black;
}
.container:hover{
    background-color:rgba(0, 0, 0, 0.043);
}

.container:hover{
}
.wrapper1{
    display: flex;
    flex-direction: column;
    width: 800px;

    overflow: hidden;         /* Versteckt den Überlauf */
    white-space: nowrap;      /* Verhindert den Zeilenumbruch */
    text-overflow: ellipsis; /* Zeigt "..." an, wenn der Text zu lang ist */
}

.date{
    font-size: 11px;
    height: 100%;
    width: 100%;
    text-align: end;
    padding: 6px;
}