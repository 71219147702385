
.textInput{

    padding-left: 10px;
    width: 99%;
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
}
.textInput:focus{
    box-shadow: 0 0 10px grey ;
}