
.container{


    width: 100%;
    height: 50px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: flex-start;

}
.container:hover{
    background-color:  rgb(245, 245, 245);
}



.container2{
    display: flex;
    flex-direction: column;
}

.profilePicture{
    margin-left: 20px;
    border-radius: 100%;
    border: 2px solid rgb(236, 236, 236);
    height: 25px;
    width: 25px;
    object-fit: cover;

}

.name{
    margin-left: 20px;
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.addresse{
    margin-left: 20px;
    color: black;
    font-size: 15px;
}