.container1{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
}
.header{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: flex-end;

}
.container{
    margin-top: 3px;
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    z-index: 5;
}


.friendsListSite{
    display: flex;
    flex-direction: column;
    width: 60%;
    height:100%;


}


.friendList{
    width: 100%;
    height:100%;
    scroll-behavior: smooth;
    overflow: scroll;
}


.menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}


.box{
    width: calc(95% - 10px);
    padding: 10px;
    border: 1px solid rgb(31, 31, 31);
    border-radius: 5px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;

}

.buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width:100%;
}

.searchInput{
    width: calc(100% - 15px);
    height: 44px;
    font-size: 14px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
    padding-left: 15px;
}




@media(max-width:1000px){

    .container{
        flex-direction: column;
    }
    .searchWrapper{

        width: 100%;
    }
    .box{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: calc(98% - 20px);
        padding-right: 10px;
    }
    .friendsListSite{
        margin-top: 20px;
        width: 100%;

    }
    .menu{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    
}


@media(max-width:800px){

    .header{
        font-size: 30px;
    }



}





.friendWidgetElement{
    width:100%;
    height: 33px;

    font-size: 12px;
    
}

.friendWidget{
    width:100%;
    height: 100%;
    background-color: yellow;
}

