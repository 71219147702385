



.approve{
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    margin-top: 10px;

}


.pwWrapper{
    width:100%;
    display: flex;
    flex-direction: column;
}