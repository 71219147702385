.container{

    width: 100vw;
    display: flex;
    flex-direction: row;
    background-color: rgb(243, 243, 243);
}



.left{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding-bottom: 50px;



}

.right{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    background-color: rgb(235, 235, 235);

}

.NFTWrapper{
    margin: 30px;
    margin-right: 5px;
    height: 470px;
    width: 440px;

}






.box{
    position: relative;
    margin: 40px;
    width: calc(100% - 80px);
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
}



.h1{
    font-size: 23px;
    color:blue;
    cursor: pointer;
}
.h1:hover{
    color: rgb(46, 46, 129);
}
.h2{

    font-size: 34px;
    font-weight: bold;
    margin-top: 15px;

}
.h3{

    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
}
.h4{
    font-size: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;

}


.nameAndButton{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}


.ownerWrapper{
    margin-left: 20px;
    margin-top: 7px;
    display: flex;
    gap: 15px;
}
.text{
    font-size: 15px;
    width: 115px;
    text-align: right;
}
.owner{
        
    font-size:15px;
    color:blue;
    cursor: pointer;
}

.owner:hover{
    color: rgb(46, 46, 129);

}
.herz{
    height: 15px;
}

.likesWrapper{
    width: 100px;
    margin-top: 20px;
    margin-bottom: 45px;
    display: flex;
    gap:1px;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
}
.likesWrapper:hover{
    opacity: 1;
}




.buyButtonWrapper{
    position:absolute;
    top: 0px;
    right: -48px;

    gap:1px;
    border-radius: 10px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
}

.niceBoxes{
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgb(192, 192, 192);
    display: flex;
    flex-direction: column;


    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
    background-color: white;


}
.topBox{
    height: 50px;
    width: 100%;
    border-bottom: 1px solid rgb(192, 192, 192);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.link{
    margin: 20px;
    font-size:15px;
    color:blue;
    cursor: pointer;
}

.descriptImg{
    height: 25px;
    width: 25px;
    margin-left:20px;
}

.place{
    height: 15px;
    width: 100%;
}

.ethPrice{
    font-size: 25px;
}

.ethPriceWrapper{
    display: flex;
    align-items: flex-end;
    margin-top: 5px;
    margin-bottom: 15px;
}

.supportsCreator{

    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-top: 7px;
}


@media(max-width: 1200px){

    .ethPrice{
        font-size: 23px;
    }

    .h1{
        font-size: 20px;
    }
    .h2{
        font-size: 30px;
    }
    .left{
        width: 45%;
    }
    .right{
        width: 55%;
    }
    .NFTWrapper{
        height: 420px;
        width: 350px;
    }
}

@media(max-width: 1000px){
    .left{
        width: 45%;
    }
    .right{
        width: 55%;
    }
.h2{
    font-size: 20px;
}
.NFTWrapper{
    height: 370px;
    width: 300px;
}
}


@media(max-width: 700px){


    .buyOfferButtonWrapper{
        display: flex;
        flex-direction: column;
    }

    .nameUndLikesWrapper{
        margin-left:20px;
    }
    .box{
        position: relative;
        margin: 10px;
        height: calc(100% - 20px);
        display: flex;
        flex-direction: column;

        margin-left: 5px;
        margin-right: 5px;

        width: calc(100% - 12px);
        
    }

    .likesWrapper{
        width: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        gap:1px;
        align-items: center;
        cursor: pointer;
        opacity: 0.5;
    }


    .NFTWrapper{
        margin: auto;
        height: 370px;
        width: 320px;
        height: 370px;

        margin-top:20px;
        margin-bottom:20px;
    }
    
    .container{
        flex-direction: column;
    }
    
    .left{
        width: 100vw;
        align-items: center;
        justify-content: center;

        padding-bottom: 15px;
    }

    .right{
        width: 100vw;
        padding-bottom:20px;

    }
    .h2{
        font-size: 20px;
    }
    .h3{
        font-size: 17px;
    }




}

@media(max-width: 500px){





}