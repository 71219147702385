

.menuButton{
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;

    height: 42px;
    padding-left: 5px;
    padding-right: 5px;

    opacity: 0.8;

    background-color: rgb(227, 227, 227);
    background-color: rgb(48, 51, 57);


    
    
    font-weight: bold;

    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    transition:  box-shadow 0.2s ease-in-out;

}
.menuButton:hover{

    background-color: rgb(213, 213, 213);
    background-color: rgb(39, 41, 46);

    

}


.text{
    font-size: 18px;

    color: black;

}



@media(max-width: 650px){

}