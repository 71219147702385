.container{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.2);


	
}
.container:hover{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 50);
}


.NFTimage{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor:pointer;
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1 !important;

}



.bottom{
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 40px;

}

.bottom2{
    width: 100%;
    height:20px;
    background-color: rgb(240, 240, 240);

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;


}

.nameAndFrom{
    margin-top: 3px;
    margin-left: 10px;
    font-size: 13px;
    overflow: hidden;         /* Versteckt den Überlauf */
    white-space: nowrap;      /* Verhindert den Zeilenumbruch */
    text-overflow: ellipsis; /* Zeigt "..." an, wenn der Text zu lang ist */

}

.nameAndNumber{
    margin-top: 1px;
    margin-left: 10px;
    margin-bottom:3px;
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;         /* Versteckt den Überlauf */
    white-space: nowrap;      /* Verhindert den Zeilenumbruch */
    text-overflow: ellipsis; /* Zeigt "..." an, wenn der Text zu lang ist */
}

.preis{
    position: absolute;
    right: 0px;
    top: 0px;
    background-color:yellow;
    width: 20px;
}



.likesWrapper{
    display: flex;
    margin-right: 10px;
    align-items: center;

}


.numberlikes{

    font-size: 13px;
    align-items: center;

}



.placeholder{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1 !important;
    animation: light 1s infinite;
}

@keyframes light{
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
}


@media(max-width: 700px){

    .nameAndFrom{
        font-size:11px;
    }

    .nameAndNumber{
        font-size: 11px;
    }


}

