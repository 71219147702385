.container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    gap: 15px;

}

.box {
    border-radius:5px;
    box-shadow: 1px 2px 4px 0px black;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(500px - 40px);
    padding: 20px;
}


.textInput{

    padding-left: 10px;
    height: 35px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
}


.headerText{
    font-size: 25px;
}


@media(max-width:700px){
    .box{
        width: calc(95% - 40px);
    } 
}