.container{
    width: 100%;
    min-height: 100vh;
    background-color: rgb(223, 223, 223);
    display:flex;
    align-items: flex-start;
    justify-content: center;

    position: relative;
}


.stripe{
    position:absolute;
    height: 1px;
    width: 100%;
    top:99px;
    background-color: rgb(155, 155, 155);
}


.header{
    margin-top: 40px;
    width: 90%;
    position: absolute;
    top: 0px;

    display: flex;

    align-items: center;
    justify-content: space-between;
}

.headerText{
    font-size: 40px;
    color: rgb(6, 29, 42);
}

.buttonWrapper{
    display: flex;
    gap: 22px;

}


@media(max-width: 700px){
    .headerText{
        font-size: 30px;
    }
}