

.komplett{
    width: 100%;
    position: absolute;

}
.backdrop{

    width: 100vw;
    height: 100vh;
    position:fixed;
    z-index:999;
    top:0px;
    right:0px;

    background-color: black;
    opacity: 0.6;

    animation-name: backdropOpacity;
    animation-duration: 0.3s;
}

.backdropOpacityTrigger{
    animation: backdropOpacityOut 0.3s;
}

@keyframes backdropOpacity {
    from {opacity: 0}
    to {opacity: 0.6}
}
@keyframes backdropOpacityOut {
    from {opacity: 0.6}
    to {opacity: 0}
}

.fenster{
    position:fixed;
    z-index: 1000;
    padding-bottom: 20px;
    min-width: 400px;
    border-radius:10px;
    background-color: rgb(255, 255, 255);

    top:150px;
    left: 50%;
    transform: translate(-50%, 0);

    animation-name: driveIn;
    animation-duration: 0.3s;

    display: flex;
    flex-direction: column;
    align-items: center;

}

.driveOut_trigger{
    animation: driveOut 0.3s;
}

@keyframes driveIn {
    from {top: 130px; opacity: 0}
    to {top: 150px; opacity: 1;}
}
@keyframes driveOut {
    from {top: 150px; opacity: 1}
    to {top: 130px; opacity: 0;}
}


.top{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom: 2px solid rgb(224, 224, 224);
    width:calc(90% - 60px);
    font-size:22px;
    text-align: center;
    text-justify: center;
    padding:16px;
    padding-left: 30px;
    padding-right: 30px;
    color:rgb(6, 29, 42);

}

.close{
    position:absolute;
    top:22px;
    right:27px;
    height: 15px;
    width: 15px;
    opacity: 0.5;
    cursor: pointer;
}
.close:hover{
    opacity: 1;
}

@media(max-width: 650px){
    .fenster{
        width:98%;
        min-width: 0px;
    }
}