.noFriendsWrapper{

    margin-top: 30px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    position: absolute;
    width: 500px;
    top:150px;

    left: 50%;
    transform: translate(-50%, 0);
}




.noFriends{

    font-size: 20px;
    opacity: 0.3;
}

.sad{
    width: 30px;
    opacity: 0.2;

}
