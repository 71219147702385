.box{
    width: 900px;
    border-radius: 15px;
    padding: 40px;
    padding-left: 60px;
    padding-right: 60px;
    background-color: #2b2c3d;
    background-color: rgb(11, 11, 11);
    display:flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    border: 1px solid rgb(58, 58, 58);


}

.header{
    display: flex;
    font-size: 35px;
}

.body{
    color: rgb(201, 201, 201);
}

.buttonWrapper{
    margin-top: 20px;
    display: flex;
    gap: 10px;
    height: 40px;
    z-index: 100;

}

.NFTWrapper{
    width: 150px;
    position: absolute;
    top: -50px;
    right: -80px;
}

h1{
    background: linear-gradient(45deg,#1131e9,#3e0270, #df710b);
    background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
    font-size: 35px;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;



}




.line{
    position: absolute;
    width: 20px;
    height: 130px;

    left: 50%;
    transform: translate(-50%, 0);
    top: -95px;
    z-index: 100;
    background: linear-gradient(70deg,#1131e9,#3e0270, #df710b);
    border-radius: 20px;
}

.line{
    position: absolute;
    width: 20px;
    height: 20px;

    left: 50%;
    transform: translate(-50%, 0);
    top: -40px;
    z-index: 100;
    background: linear-gradient(70deg,#1131e9,#3e0270, #df710b);
    border-radius: 20px;
}

.line2{
    position: absolute;
    width: 2px;
    height: 170px;

    left: 50%;
    transform: translate(-50%, 0);
    top: -170px;
    z-index: 100;
    background: linear-gradient(70deg,#1131e9,#3e0270, #df710b);

}

.dot{
    position: absolute;
    width: 20px;
    height: 20px;

    left: 50%;
    transform: translate(-50%, 0);
    top: -130px;
    z-index: 100;
    background: linear-gradient(70deg,#1131e9,#3e0270, #df710b);
    border-radius: 20px;
    cursor: pointer;
   /* animation: move 2s ease 0s infinite normal none; */
}

.bottom {
    width: calc(100% + 120px);
    margin-left: -60px;
    margin-bottom: calc(-60px - 70px);
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    top: -90px;

    /* Transparenz am oberen Rand */
    -webkit-mask-image: linear-gradient(to top, 
        rgba(0, 0, 0, 1) calc(100% - 90px), /* Bis 40px vor dem oberen Rand 100% sichtbar */
        rgba(0, 0, 0, 0) 100%               /* Komplett transparent am oberen Rand */
    );
    mask-image: linear-gradient(to top, 
        rgba(0, 0, 0, 1) calc(100% - 90px), 
        rgba(0, 0, 0, 0) 100%
    );
}




@keyframes move {
    0%{top: -190px;}
    50%{top: -196px;}
    100%{top: -190px;}
}

.dot:hover{
    width: 28px;
    transition: width 0.3s

}

.bottomdot{
    position: absolute;
    width: 20px;
    height: 20px;

    left: 50%;
    transform: translate(-50%, 0);
    bottom: -40px;
    z-index: 100;
    background: linear-gradient(70deg,#1131e9,#3e0270, #df710b);
    border-radius: 20px;

}



@media(max-width: 900px){

    .box{
        width: calc(95% - 60px);
        padding: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .bottom {
        width: calc(100% + 60px);
        margin-left: -30px;
        margin-bottom: calc(-30px - 70px);
    
        position: relative;
        top: -70px;
    

    }

    .NFTWrapper{
        width: 150px;
        position: relative;
        top: 0px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .header{
        display: flex;
        font-size: 22px;
    }
    h1{
        font-size: 22px;
    }
    .body{
        font-size: 15px;
    }

    .buttonWrapper{
        margin-top: 20px;
        display: flex;
        gap: 10px;
        height: 40px;
        justify-content: center;
    }
}