.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  height: 30px ;


}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top: 3px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinnderDark{
  width: 24px;
  height: 24px;
  border: 3px solid rgba(111, 111, 111, 0.2);
  border-top: 3px solid #b3b3b3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
