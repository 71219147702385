.integration{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    height: calc( 100% - 80px - 90px);
}

.box{

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.friendElement{

    background-color: yellow;
    width: 100%;
}