

.integration{
    width: 100%;
    flex: 1;   

    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
    overflow-y: auto;  /* Ermöglicht Scrollen, falls die Liste zu lang ist */
}

.totalAmount{

    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 17px;

    width: 90%;

    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
    gap: 10px;
}

.asset{

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;

    width: 90%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;

    height: 50px;
}
.asset:hover{
    opacity: 0.8;
}

.sendButtonAsset{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 0px;

    cursor: pointer;
}
.sendButtonAsset:hover{
}



.assetList{
    width: 100%;
    overflow-y: auto;  /* falls die Liste länger wird, wird gescrollt */
    display: flex;
    flex-direction: column;
    align-items: center;

     gap: 10px;


}
