.container{

    opacity: 1;
    width: 100%;
    border-bottom: 1px solid grey;
    background-color: rgb(6, 29, 42);
    background-color: rgb(26, 26, 26);

    display:flex;
    flex-direction: column;
    align-items: center;

}

.container2{
    margin-top: 100px;
    width: 80%;
    display:flex;


    gap: 50px;
}


.box{

    height: 100%;
    width: 240px;
}
.box2{
    width: 120px;
}

.header{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
    color: rgb(201, 201, 201);
}
.text{
    font-size: 15px;
    color: rgb(201, 201, 201);
}
.h2{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 40px;
    color: rgb(201, 201, 201);
}
.h3{
    font-size: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    color: rgb(201, 201, 201);
}
.h3:hover{
    color: white;
}

.h4{
    font-size: 13px;
    color: rgb(201, 201, 201);
}


.bottom{
    margin-top: 50px;
    border-top: 1px solid rgb(201, 201, 201);
    width: 80%;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



.logoWrapper{
    height:120px;
    width:153px;
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-bottom: 10px;
    align-items: flex-start;
    justify-content: flex-start;
}





.icon{
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.342);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.icon:hover{
    background-color: rgba(255, 255, 255, 0.253);
    
}

.iconsWrapper{
    display: flex;
    gap: 10px;
}


@media (max-width:1000px){

    .container2{

        flex-direction: column;
    }
    .bottom{
        flex-direction: column;
        justify-content: space-evenly;

    }
}