
.integration{

    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.pic{
    height:100%;
}

.buttonText{
    font-size: 15px;

}

.wrongNetwork{
    display: flex;  
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
    height: 80px;
    width: 70%;
    border: 1px solid black;
    box-shadow: 0px 0px 7px 5px rgb(250, 0, 0);

}

.walletButton{
    display: flex;  
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
    height: 40px;
    width: 80%;
    border: 1px solid black;
    box-shadow: 4px 4px 7px grey;

    cursor: pointer;
    
}

.walletButton:active{
    box-shadow: none;
}
.redText{
    margin: 5px;
    color: rgb(255, 0, 0);
    cursor: pointer;
    font-size: 14px
}
@media(max-width: 650px){
    .container{
        width:95%;
    }
}