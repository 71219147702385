.container{

    width: 100% -padding;
    height: 100vh;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;

}




