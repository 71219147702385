.backdrop{
    width: 100vw;
    height: 100vh;
    position:fixed;
    right: 0px;
    top:0px;
    background-color: rgb(0, 0, 0);
    z-index: 20000;
    opacity: 0.3;

    transition: opacity 0.5s;
}

.wallet{
    width:400px;
    height:calc(100vh - 74px);
    height: calc((var(--vh, 1vh) * 100) - 74px);
    position:fixed;
    top: 71px;
    right:0px;
    z-index: 20001;
    backdrop-filter: blur(22px);

    animation-name: slideIn;
    animation-duration: 0.5s;
    border-radius: 15px;

    transition: right 0.5s;

    box-shadow: 10px 10px 20px 8px black;

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}


@keyframes slideIn {
    0% {right: -500px}
    100% {right: 0px}
}

@keyframes slideInBottom {
    0% {top: 1500px}
    100% {top: 90px}
}


.header{
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.totalAmount{

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 85%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.asset{

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 85%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}


@media(max-width:800px){
    .wallet{
        width: 100%;

        transition: top 0.5s;

        animation-name: slideInBottom;
        animation-duration: 0.5s;
    


    }

}