

.container{

    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;




}

.topBox{
    width: 100%;
    height: 180px;
    background-color: rgb(239, 239, 243);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-bottom:40px;
    gap:20px;
}

.header{
    font-size: 35px;
    font-weight: bold;
}

.searchBox{

    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.textInput{
    margin-left: 20px;

    padding-left: 10px;
    width: 99%;
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
    width: 400px;
}
.textInput:focus{
    box-shadow: 0 0 10px grey ;
}


.grid{
    margin-top:10px;
    width: 95%;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    padding:0px;

    gap: 10px;

    margin-bottom: 30px;
    position: relative;
}





@media(max-width:1200px){

}

@media(max-width:800px){

    .textInput{
        margin-left: 5px;
        margin-right: 2px;
        padding-left: 10px;
        width: 99%;
        height: 40px;
        font-size: 15px;
        border-radius: 10px;
        border: 2px solid rgb(209, 209, 209);
    }
}