
.container{

    width: 100%;
    
}

.header{

    width: 100%;
    height: 100px;

    display:flex;
    align-items: center;
    justify-content: center;

}


.list{
    scroll-behavior:smooth;

    overflow-y: scroll;

    height: calc(100vh - 165px);

    display: flex;
    flex-direction: column; /* Wichtig für das vertikale Nachrutschen */
}

