.carouselContainer {
  position: relative;
  width: 100%;
  height: 650px; /* Gesamt-Höhe des Carousel-Containers */
  overflow: hidden;
  height: calc(650px + 70px); 


}



.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(30px);
  transform: scale(1.1);
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s ease;
}

.nextBackground {
  opacity: 0;
}

.overlay {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 32px;


}



/* Setze hier die Höhe des Carousels auf 100% der übergeordneten Höhe */
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; /* zentriert die NFTs vertikal */
}

.button {
  background-color: rgba(148, 148, 148, 0.8);
  cursor: pointer;
  width: 25px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 8px;
  opacity: 0.2;
  /* Die Höhe wird nun per Inline-Style aus JS gesetzt */
}
.button:hover{
  opacity: 0.3;
}

/* Slider-Container mit Flexbox, damit die NFTs zentriert werden */
.sliderWrapper {
  overflow: hidden;
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.slider {
  display: flex;
  align-items: center; /* Inhalte (NFTs) behalten ihre natürliche Höhe */
}

/* Entferne die Höhenvorgabe, damit die NFTs nicht gestreckt werden */
.nftWrapper {
  margin-right: 7px;
  /* keine Höhe-Vorgabe */
}


@media (max-width: 1800px) {
  .carouselContainer {
    height: calc(400px + 64px); 
  }
  .overlay {
    top: 32px;
  }
}
@media (max-width: 1200px) {
  .carouselContainer {
    height: 300px; 
    height: calc(300px + 64px); 
  }
  .overlay {
    top: 32px;
  }
}

/* In der mobilen Ansicht (kleinere Bildschirme) die Buttons ausblenden */
@media (max-width: 768px) {
  .button {
    display: none;
  }



}



