.list{
    border-top: 1px solid rgb(212, 212, 212);
    width: 100%;
    height: 100%;
    overflow: scroll;
}


.txElement{
    height: 55px;
    width: calc(100% - 10px);
    border-bottom: 1px solid rgb(212, 212, 212);
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 5px;
    position: relative;

}
.txElement:hover{
    background-color: rgba(65, 58, 116, 0.068);
}

.icon{
    margin-left: 3px;
}

.actionImgWrapper{
    margin-left: 5px;
    background-color: rgba(0, 0, 255, 0.2); /* Blau mit 50% Opazität */
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
}


.time{
    font-size: 12px;
    color: green;
}

.fromto{
    font-size: 12px;

}
.contractInteraction{
    font-size: 12px;
    color: blue;
}
.contractInteraction:hover{
    color: rgba(0, 0, 245, 0.589);

}

.header{
    opacity:0.6;
    padding:10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    position: relative;
    width: calc(100% - 20px);
    
}

.headerText{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


