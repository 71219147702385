.container{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.2);

}
.container:hover{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 50);
}


.NFTimage{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
    aspect-ratio: 1 / 1 !important;

}


.bottom{
    height: 60px;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;


}


.name{
margin:0px;
    margin-top: 3px;

    font-size: 18px;
    font-weight: bold;

}

.creator{
    margin-top: 2px;
    font-size: 15px;
    margin-bottom:3px;
}

.placeholder{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1 !important;
    animation: light 1s infinite;
}

@keyframes light{
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
}



@media(max-width: 700px){

    .name{
        font-size: 11px;
    }

    .creator{
        font-size: 11px;
    }
}