.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    gap:0px;
}

.greyBox{
    z-index: 1;
    background-color: rgb(211, 211, 211);
    height: 150px;
    width: 100%;
    position: absolute;
    

    display: flex;
    align-items: center;
    justify-content: center;
}


.profilePicture{
    z-index: 2;
    margin-top: 90px;

    margin-bottom: 0px;
    object-fit: cover;
}


.backgroundPic{
    height:100%;
    width: 100%;
    object-fit: cover;

}

.name{
    margin:5px;
    font-size: 30px;
    font-weight:bold;

}

.addressWrapper{
    display: flex;
    border: 3px solid rgb(211, 211, 211);
    border-radius: 8px;
    align-items: center;
    gap:3px;
    height: 22px;
}
.cryptoSign{
    height: 20px;
}

.address{
    font-size: 15px;

}


.buttonPosition{

    z-index: 2;
    position:absolute;
    top: 160px;
    right: 50px;


}






.textInput{
    margin:5px;
    margin-top: 25px;
    padding-left: 10px;
    height: 35px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
}






.hiddenFileButton{

    z-index: 2;
    height: 100%;
    width: 100%;
    cursor:pointer;
    border: 3px dashed grey ;
    border-radius: 5px;
}

.hiddenFileButton:hover{
    background-color: rgb(201, 201, 201);
    opacity: 0.3;
}
.image{
    height: 100%;
    width: 100%;
    object-fit: cover;

}
.coverImage{
    height: 30%;
    width: 30%;
    opacity: 0.3;
}




/* Image Settings*/


.imageUploadWrapper{

    background-color: white;
    z-index: 10;
    margin-top: 90px;
    border-radius: 100%;
    height: 100px;
    width:100px;
    position: relative;
    display:flex;
    align-items: center;
    justify-content: center;
}

.imageInput{

    height: 0.1px;
    width: 0.1px;
    visibility: hidden;
}

.hiddenFileButton{

    z-index: 2;
    height: 100%;
    width: 100%;
    cursor:pointer;
    border: 3px dashed grey ;
    border-radius: 100%;
}
.hiddenFileButton:hover{
    background-color: rgb(201, 201, 201);
    opacity: 0.3;
}
.image{
    height: 100%;
    width: 100%;
    position: absolute;

}
.coverImage{
    height: 30%;
    width: 30%;
    opacity: 0.3;
    position: absolute;
}

.followWrapper{
    margin-top: 40px;
    display: flex;
    gap:8px;
    align-items: center;
    cursor: pointer;
    opacity: 0.5;
}
.followWrapper:hover{
    opacity: 1;
}
.herz{
    height: 15px;
}



@media(max-width: 700px){
    .buttonPosition{

        right: 5px;
    }
}