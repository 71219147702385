

.container{

    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;


}

.topBox{
    width: 100%;
    background-color: rgb(239, 239, 243);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top:20px;
    padding-bottom: 15px;
    margin-bottom:40px;
    gap:20px;
}

.header{
    font-size: 35px;
    font-weight: bold;
}

.searchBox{

    width: 100%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.textInput{
    margin-left: 20px;

    padding-left: 10px;
    width: 99%;
    height: 40px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgb(209, 209, 209);
    width: 400px;
}
.textInput:focus{
    box-shadow: 0 0 10px grey ;
}


.grid{
    margin-top:10px;
    width: 98%;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-rows: 1fr;
    padding:0px;

    gap: 10px;

    margin-bottom: 30px;
    position: relative;
}


.toggleButtonWrapper{
    width: 300px;
}

.searchFilterWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media(max-width:800px){
    
    .searchFilterWrapper{
        width: 100%;
    }

    .searchBox{
        flex-direction: column;
        width:98%;
    }
    .toggleButtonWrapper{
        width: 100%;
    }

    .textInput{


        margin-top: 0px;
        margin-left: 0px;     
        width: calc(98%);
        font-size: 15px;

        height: 33px;

    }


}