.integration{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:20px;
  height: calc( 100% - 80px - 90px);


}

.box{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  width: 90%;
  margin-left: 10px;
  margin-right: 10px;
  height: calc(100% - 4px);
  border: 1px solid rgb(212, 212, 212);
  border-radius: 5px;

  flex: 1;
  overflow-y: auto;

}

.header{
  opacity:0.6;
  padding:10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 20px);
  
}

.headerText{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}


