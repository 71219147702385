.integration{


    display: flex;
    flex-direction: column;
    align-items: center;

}




.textInput{

    padding-left: 10px;
    width: calc(100% - 10px);
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
}
.textInput:focus{
    box-shadow: 0 0 10px grey ;
}


.inputwrapper{
    margin-top:30px;
    display:flex;
    align-items:flex-start;

    gap: 20px;

}
.inputwrapper2{
    display:flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    gap: 10px;
}
.equalimg{
    height: 35px;
    width: 40px;
}

.usdAmount{
    font-size: 20px;

}
.box{
    display:flex;
    flex-direction: column;
    gap:10px;
}
.button{
margin-top: 7px;
}


@media(max-width: 650px){
    .container{
        width:95%;
    }
}