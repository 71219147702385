.container{
    background-color: white;

    border-radius: 10px;

    top:20%;



    position: fixed;

    left: 50%;
    transform: translate(-50%, 0);
    z-index: 20;

    width:300px;



}
.container:hover{
    box-shadow: 0 0 400px rgba(81, 101, 155, 0.329);
}

.info{

}
.txHash{

    color: rgb(45, 15, 153);
    cursor: pointer;
}
.txHash:hover{
    text-decoration: underline;
}

.desriptionWrapper{
    width:100%;
    display: flex;
    margin-top: 15px;
    border-radius: 10px;
    box-shadow: 0 1px 13px rgba(0, 0, 0, 0.2);
    padding: 3px;
}
.textWrapper1{
    font-size: 15px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.textWrapper2{
    font-size: 15px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.loaderGif{

    width:25%;

}



@media(max-width: 700px){


    .textWrapper1{
        font-size: 9px;
    }
    .textWrapper2{
        font-size: 9px;
    }

    .container{
        height: 300px;
        width: 250px;
        padding: 30px;
    }

}

@media(max-width: 600px){

    .container{
        height: 270px;
        width: 225px;
        padding: 30px;
    }

}