
.container{


    width: 100%;

    height: 40px;
    padding-bottom: 6px;
    padding-top: 6px;



    display: flex;
    align-items: center;
    justify-content: space-between;

}
.container:hover{
    background-color: rgba(0, 0, 0, 0.055);
}

.text{
    margin-left: 15px;
    display: flex;
    gap:4px;
}


@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(-50px);
    }
}

.notificationExit {
    animation: fadeOut 0.2s forwards;
}

.notification-enter {
    animation: slideIn 0.2s ease-in-out;
}