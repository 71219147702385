.container{

  z-index:600;
  width: 100%;
  height: 64px;
  position: sticky;
  top:0;
  display: flex;
  justify-content: space-between;
  align-items:center;

  background-color: rgb(6, 29, 42);
  background-color: rgb(11, 11, 11);
  background-color: red;
  box-shadow: 0 0 10px 1px rgba(53, 53, 53, 0.5);



}

.container1{
  position: sticky;
  width: 100%;
  height: 64px;
  top:0;
  z-index:600;


}

.logowrapper{
  display: flex;
  gap: 20px;
}

.logoimg{
  height: 50px;
}

.logo{
  margin-left: 60px;
  font-size: 33px;
  border-style:hidden;
  cursor: pointer;
background-color: inherit;

}
.logo:active {
  border-style: hidden;
}
.logo:hover{
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}



.menuWrapper{
   margin-right: 50px;
  display: flex;
  align-items: center;

}

.loginButton{
  cursor: pointer;
  margin-right: 30px;
  font-size: 20px;
border-style:hidden;
background-color: inherit;
}

.loginButton:hover{
  color: rgb(0,124,255);
  background: linear-gradient(70deg,#3e0270, #df710b);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}



.menu{
  cursor: pointer;
  margin-right: 20px;
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3 px;

}






@media (max-width:800px){

  .logowrapper{
    margin-left: 30px;
  }

  .logo{
    margin-left: 18px;
    font-size: 20px;

  }
  .menuWrapper{
    margin-right: 18px;

  }
  .loginButton{
    margin-right: 20px;
    font-size: 16px;

  }
}
@media (max-width:500px){

  .logowrapper{
    margin-left: 10px;
  }
  .logo{

    font-size: 16px;

  }
  .menuWrapper{
    margin-right: 20px;

  }
  .loginButton{
    margin-right: 10px;
    font-size: 16px;

  }
}

