
.container{
    width: 100%;
    height: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.container:hover{
    box-shadow: 0 0px 7px  0px rgba(1, 2, 0, 50);
}

.name{
    margin-left: 40px;
    color: black;
    font-size: 15px;
    font-weight: bold;
}

.addresse{
    margin-left: 40px;
    color: black;
    font-size: 15px;
}

.profilePicture{
    margin-left: 20px;
    border-radius: 100%;
    border: 2px solid rgb(236, 236, 236);
    height: 25px;
    width: 25px;
    object-fit: cover;

}