.container1{

  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;


}

.container{

  width: 250px;
  height: 250px;
  position: relative;

}


.line{
  height: calc(100% / 8);
  width: 2px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 100vw 120vh;
  background-position: inherit;
  background-repeat: no-repeat;
  animation-name: lineFlyIn;
  animation-duration: 3s;


}
.line:hover{

}

@keyframes lineFlyIn {
  0% {opacity: 0;width: 3px;}
  80%{opacity: 0;width: 2px;}
  90% {opacity: 1;width: 4px;}
  100%{opacity: 1; width: 2px;}
}


.dot1{
  height: 5px;
  width: 5px;
  border-radius: 20px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 100vw 120vh;
  background-position: inherit;
  background-repeat: no-repeat;

}
.dot1:hover{
  background:none;
  background-color: grey;
}



.dot2{
  height: 10px;
  width: 10px;
  border-radius: 20px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 100vw 120vh;
  background-position: inherit;
  background-repeat: no-repeat;

}
.dot2:hover{
  background:none;
  background-color: grey;
}

.dot3{
  height: 20px;
  width: 20px;
  border-radius: 20px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 100vw 120vh;
  background-position: inherit;
  background-repeat: no-repeat;

}
.dot3:hover{
  background:none;
  background-color: grey;
}