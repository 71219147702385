@keyframes blink {
    from{
        opacity: 0%;
     }
     to{
         opacity: 100%;
      }
 }
 .type-writer::after{



content: "|";
 animation: blink 1s infinite;
 }
 .type-writer > span{
 }



 .type-writer{
    font-family: monospace;
    font-size: 30px;
    color: white;
 }
 .type-writer:hover{
    color: rgb(0,124,255);
    background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
 }

 @media(max-width:1000px){
    .type-writer{
        font-size: 25px;
    }
}
@media(max-width:800px){
    .type-writer{
        font-size: 20px;
    }
}
@media(max-width:650px){
    .type-writer{
        font-size: 18px;
    }
}
@media(max-width:500px){
    .type-writer{
        font-size: 15px;
    }
}

