
.integration{
    width: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
    gap: 10px;
    padding-top: 10px;

}


.list{
    width: 100%;
    border-top: 1px solid rgb(212, 212, 212);
    display: inline;
    overflow: scroll;
    z-index: 100;
    height:340px;


}

