.container {
  text-align: center;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;


}

.address {
  font-family: monospace;
  word-break: break-all;
  margin: 10px 0;
}

.qrContainer {
  margin: 20px auto;
  width: fit-content;

  border: 8px solid white;
  border-radius: 7px;
}

.copyButton {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  transition: background-color 0.2s;
  width: 60%;

}

.copyButton:hover {
  background-color: #0056b3;
}

.info {
  font-size: 0.8em;
  color: #666;
  margin-top: 10px;
}
