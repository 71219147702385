.container1{

  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.container{

  width: 50px;
  height: 50px;
  position: relative;

}



.dot1{
  height: 1px;
  width: 1px;
  border-radius: 20px;
  position: absolute;
  background-color:grey;


}
.dot1:hover{
  background:none;
  background-color: white;
}

.dot2{
  height: 2px;
  width: 2px;
  border-radius: 20px;
  position: absolute;
  background-color:grey;

}
.dot2:hover{
  background:none;
  background-color: white;
}

.dot3{
  height: 4px;
  width: 4px;
  border-radius: 20px;
  position: absolute;
  background-color:grey;


}
.dot3:hover{
  background:none;
  background-color: white;
}


