.element{
    width: 98%;
    height: 45px;
    font-size: 30px;
    border-radius: 3px;
    background-color:white;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name{
    margin-left: 15px;
    font-size:16px;

 
}

.wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    right: 10px;
}



.nameWrapper{
    display: flex;
    justify-content: center;
    align-items:center;
    gap:15px;
    position: relative;
}



.profilePicture{
    margin-left: 20px;
    border-radius: 100%;
    border: 2px solid rgb(236, 236, 236);
    height: 25px;
    width: 25px;
    object-fit: cover;

}



@media(max-width:700px){
    
    .name{
        font-size:15px;
        margin-left: 5px;

    }
    .cryptoWrapper{
        visibility: hidden;
        width: 0.1px;;

    }


    .profilePicture{
        margin-left: 5px;
        height: 20px;
        width: 20px;

    
    }

}