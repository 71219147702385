.container{

    width: 100% -padding;
    height: calc(100vh - 64px);
    background-color: rgb(6, 29, 42);
    background-color: rgb(11, 11, 11);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    padding: 30px;
    padding-left: 70px;

    overflow: hidden;

    z-index: 10;


}


.writerSite{
    
    height:350px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

}

.header{
    font-size: 40px;
    background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

.logoMedium{
    visibility: hidden;
    position: absolute;
}

@media (max-width:1200px) {
    .container{
        height:120vh;
        flex-direction: column;
        padding-left: 30px;
        padding: 5px;
        gap: 0px;
    }

    .writerSite{
        flex-direction: column;
        align-items: center;
        height: 200px;
        gap: 15px;
    }
}

@media (max-width:900px) {
    .header{
        font-size: 30px;
    }
}

@media (max-width:700px) {
    .container{
        height:100vh;
    }

    .logoMedium{
        visibility: visible;
        position: relative;
    }
    .logoBig{
        visibility: hidden;
        position: absolute;
    }
}



