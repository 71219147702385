.container{
    width: 100%;
    display: flex;
    flex-direction: row;


    position: relative;
    z-index: 2;
}


.widget{
    width: calc(100% - 40px);
    border-radius: 5px;
    display: flex;
    margin:20px;
    margin-bottom: 0px;


    z-index: 10;


    opacity: 0.97;

}


.spalte{
    width:50%;
    display: flex;
    flex-direction: column;

}
.spalteBreit{
    width:65%;
}
.spalteEng{
    width:35%;
}

.grid{
    margin-top:10px;
    width: 98%;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-rows: 1fr;
    padding:0px;

    gap: 10px;

    grid-template-columns:repeat(3, 1fr);
    margin-bottom: 30px;
    position: relative;
}


.logoBackground{

    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}



@media(max-width: 900px){
    .container{
        flex-direction: column;
    }
    .spalte{
        width:100%;
        display: flex;
        flex-direction: column;
    
    }
    .widget{
        margin: 6px;
    width: calc(100% - 12px);

    }

}