.container{
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
}


.container2{
    width: 1000px;
    height: 90%;
    border-radius: 5px;
    display: flex;
}


.menu{
    width: 30%;
    height: 100%;
    scroll-behavior: smooth;
    overflow-y: scroll;
}
.menuHeader{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

}
.chatWindow{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;

    transition: right 0.4s;
    background-color: white;


}

.chatPartner{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;

    justify-content: space-between;
}
.messageArea{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    scroll-behavior: auto;
    overflow-y: scroll;
    overflow-x: hidden;  /* Verhindert horizontales Scrollen */
    touch-action: pan-y; /* Ermöglicht nur vertikales Scrollen auf Touch-Geräten */
    scroll-margin-bottom: 0;

    position: relative;
}

.messageField{
    background-color: white;
    width: 100%;
    display: flex;

    position: sticky;
    bottom:-40px;

}

.textInput{

    padding-left: 10px;
    width: calc(99% - 10px);
    height: 30px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
}

.backButton{
    display:none;
}

.friendName{
    font-weight:bold;
    font-size:15px;
}




@media (max-width:1000px){

    .container{
        width: 100%;

        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc((var(--vh, 1vh) * 100) - 56px);

    }

    .container2{
        height: 100%;
        width: 100%;
    }

    .menu{
        width: 100%;
        height: 100%;
        scroll-behavior: smooth;
        overflow-y: scroll;
    }
    .chatWindow{
        width: 100%;
        height: calc((var(--vh, 1vh) * 100) - 60px);

        display: flex;
        flex-direction: column;
        position: fixed;
        transition: all 0.6s ease;
        z-index: 1000;

        right: -1000px;
    }
    .chatWindowOpen {
        width: 100%;
        height: calc((var(--vh, 1vh) * 100) - 60px);

        position: fixed;
        display: flex;
        flex-direction: column;
        transition: all 0.6s ease;
        z-index: 1000;
        right: 0px;

      }

    .messageField{
      
    
    }

    .chatPartner{
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    
    .backButton{
        display:flex;

    }

    .friendName{
        font-weight:bold;
        font-size:14px;
    }


}