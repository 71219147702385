.container{
    width: 100%;
    min-height: 100vh;
    background-color: white;
    display:flex;
    justify-content: center;
}

.container2{

    width: 55%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;


}

.h1{
    margin-top:40px;
    font-size: 44px;

}
.h2{
    margin-top: 20px;
    font-size:22px;

}

.h3{
    margin-top:0px;
    font-size:13px;

}

.textInput{
    margin-bottom: 0px;

    padding-left: 10px;
    width: calc(99% - 10px);
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
}
.textInput:focus{
    box-shadow: 0 0 10px grey ;
}

.textInputBig{
    margin-bottom: 0px;

    padding-left: 10px;
    width: calc(99% - 10px);
    height: 80px;
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid rgb(209, 209, 209);
}
.textInput:focus{
    box-shadow: 0 0 10px grey ;
}

.fehlermeldung{
    font-size: 15px;
    color: red;
    margin-bottom:0px;
}







.imageUploadWrapper{

    height: 250px;
    width:250px;
    position: relative;
    display:flex;
    align-items: center;
    justify-content: center;
}

.imageInput{

    height: 0.1px;
    width: 0.1px;
    visibility: hidden;
}

.hiddenFileButton{

    z-index: 2;
    height: 100%;
    width: 100%;
    cursor:pointer;
    border: 3px dashed grey ;
    border-radius: 5px;
}
.hiddenFileButton:hover{
    background-color: rgb(201, 201, 201);
    opacity: 0.3;
}
.image{
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: cover;
}
.coverImage{
    height: 30%;
    width: 30%;
    opacity: 0.3;
    position: absolute;
}


.place{
    width: 100%;
    height: 50px;
}

.createButton{
    display:flex;
    gap: 20px;
    padding-top: 25px;
}




@media (max-width: 900px){
    .container2{

        width: 95%;
    }
}



@media (max-width: 700px){

}