.container {
  position: relative;
  display: inline-block;
  cursor: pointer;


  border-radius: 50%;
  
  
}

.profilePicture {
  width: 103px;
  height: 103px;
  border-radius: 50%;
  object-fit: cover;

  position: relative;
  top: 3px;
  border: 2px solid rgba(128, 128, 128, 0.345);

  margin-top: -3px;
  margin-bottom: -2px;

}

/* Overlay, das das Icon zentriert */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s;

  border-radius: 100%;

}

/* Overlay beim Hover sichtbar */
.container:hover .overlay {
  opacity: 1;
}
