.container{


    width: calc(100vw);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    gap:60px;
    padding-top: 60px;

}








