

.backdrop{
    background-color: black;
    opacity: 20%;
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  
  
  }
  