
.box{

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.backButtonWrapper{
    display: flex;
    align-items: center;
    gap:5px;
    width: 90%;
    margin-top: -10px;
    margin-bottom: -10px;
}

.textInput{

    padding-left: 10px;
    width: calc(100% - 10px);
    height: 40px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgb(209, 209, 209);
}
.textInput:focus{
    box-shadow: 0 0 10px grey ;
}


.inputMaxWrapper{
    display: flex;
    gap:10px;
    width: 95%;
}



.equalimg{
    height: 25px;
    width: 30px;
}


