.container{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;

    padding: 30px;


}
.friends{
    text-align: center;
    color:rgb(6, 29, 42);
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;

}

.text{
    text-align: center;
    font-size: 20px;

}

.buttonWrapper{
    margin-top:  20px;
    display: flex;
    gap: 10px;
    height:40px;
}
