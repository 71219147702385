

.integration{
    text-align: center;
    margin-top: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 90%;

}


.input{
    padding-left: 10px;
    width: calc(100% - 12px);
    height: 44px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid rgb(209, 209, 209);
}


.text{
    width: 120px;
    font-size:  14px;
}



.validImage{
    height: 50px;

}

.validAddr{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 13px;
    margin-top: -15px;
    margin-left: 5px;
}
.validPicture{
    filter: brightness(0) saturate(100%) hue-rotate(90deg) invert(50%) sepia(100%) saturate(10000%) hue-rotate(90deg);
}
.invalidPicture{
    filter: brightness(0) saturate(100%) hue-rotate(0deg) invert(50%) sepia(100%) saturate(10000%) hue-rotate(0deg);
}


@media(max-width:1200px){


}

@media(max-width:900px){

    .container{
        width: 70%;
    }
}





@media(max-width: 650px){
    .container{
        width:95%;
    }
}