.container1{

  width: 550px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.container{

  width: 500px;
  height: 500px;
  position: relative;

}


.line{
  height: calc(100% / 8);
  width: 3px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 600px 100vh;
  background-position: inherit;
  background-repeat: no-repeat;

  animation-name: lineFlyIn;
  animation-duration:3s;


}
.line:hover{

}

@keyframes lineFlyIn {
  0% {opacity: 0;width: 3px;}
  80%{opacity: 0;width: 3px;}
  90% {opacity: 1;width: 7px;}
  100%{opacity: 1; width: 3px;}
}


.dot1{
  height: 10px;
  width: 10px;
  border-radius: 20px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 600px 100vh;
  background-position: inherit;
  background-repeat: no-repeat;

}
.dot1:hover{
  background:none;
  background-color: grey;
}

.dot2{
  height: 20px;
  width: 20px;
  border-radius: 20px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,red);
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 600px 100vh;
  background-position: inherit;
  background-repeat: no-repeat;

}
.dot2:hover{
  background:none;
  background-color: grey;
}

.dot3{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  position: absolute;
  background: linear-gradient(70deg,#1976d2,red);
  background: linear-gradient(70deg,#1976d2,#3e0270, #df710b);
  background-attachment: fixed;
  background-size: 600px 100vh;
  background-position: inherit;
  background-repeat: no-repeat;

}
.dot3:hover{
  background:none;
  background-color: grey;
}



@media (max-width: 700px) {

  .dot1{
    background-size: 0px 100vh;

  }
  .dot2{
    background-size: 0px 100vh;

  }
  .dot3{
    background-size: 0px 100vh;
    
  }
  .line{
    background-size: 0px 100vh;
  }
  .container{
    width:0px;
  }
  .container1{
    width:0px;
  }
}

@media (max-width: 1200px) {

  .dot1{
  background-position: center;
  }
  .dot2{
  background-position: center;
  }
  .dot3{
    background-position: center;
  }
  .line{
    background-position: center;
  }

  
}