.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    width: 90%;
    height: 90px;
  }
  
  .actionButton {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .squareButton {
    width: 50px;
    height: 50px;
    border: none;
    background-color: #f0f0f0;
    border-radius: 8px; /* Alternativ: 50% für runde Buttons */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .squareButton:hover {
    opacity: 0.7;
  }
  
  .actionLabel {
    margin-top: 4px;
    font-size: 14px;
    text-transform: lowercase;
  }
  