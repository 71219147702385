.backdrop{
    width: 300vh;
    height: 100vw;
    position:fixed;
    right: 0px;
    top:0px;
    background-color: rgb(0, 0, 0);
    z-index: 2000;
    opacity: 0.3;

    transition: opacity 0.5s;
}

.wallet{
    width:400px;
    height:100vh;
    background-color: white;
    position:absolute;
    top: 55px;
    right:0px;
    z-index: 2001;
    opacity: 1;
    animation-name: slideIn;
    animation-duration: 0.5s;

    transition: right 0.5s;

    box-shadow: 10px 10px 20px 8px black;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap:20px;
}


@keyframes slideIn {
    0% {right: -500px}
    100% {right: 0px}
}


.header{

    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid rgb(212, 212, 212);
    justify-content: space-between;
}

.totalAmount{

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 85%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.asset{

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 85%;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 5px;
}


@media(max-width:767px){
    .wallet{
        width: 100%;
    }
    .backdrop{
        top:66px;
        opacity: 0;
        z-index: 0;
    }
}