

.menuButton{
    display: none;
}

.buttonBalken{
    background-color: black;
    height: 3px;
    width: 100%;
    border-radius: 10px;

}

.buttonBalken1{
    background-color: black;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    transform: translateY(8px) rotate(-45deg);
    animation: menuKreuz1 0.3s;
}
.buttonBalken2{
    background-color: black;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    opacity: 0;
    animation: menuKreuz2 0.1s;
}
.buttonBalken3{
    background-color: black;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    transform: translateY(-8px) rotate(45deg);
    animation: menuKreuz3 0.3s;
}
.buttonBalken1close{
    background-color: black;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    animation: menuKreuz1reverse 0.3s;

}
.buttonBalken2close{
    background-color: black;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    opacity: 1;
    animation: menuKreuz2reverse 0.3s;

}
.buttonBalken3close{
    background-color: black;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    animation: menuKreuz3reverse 0.3s;
    animation-direction: alternate;

}

@keyframes menuKreuz1 {
    0% { transform: none; }
    100% { transform: translateY(8px) rotate(-45deg); }
}
@keyframes menuKreuz1reverse {
    0% { transform: translateY(8px) rotate(-45deg); }
    100% { transform: none; }
}
@keyframes menuKreuz2 {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
@keyframes menuKreuz2reverse {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes menuKreuz3 {
    0% { transform: none; }
    100% { transform: translateY(-8px) rotate(45deg); }
}
@keyframes menuKreuz3reverse {
    0% { transform: translateY(-8px) rotate(45deg); }
    100% { transform: none; }
}




@media(max-width: 650px){
    .menuButton{
        width: 46px;
        display: flex;
        flex-direction: column;
        gap:5px;
    }    
}