.container {
    position: relative;
    font-family: Arial, sans-serif;
    width: 100%;

    padding-top: 5px;
    padding-bottom: 5px;


  }
  
  .topRow {
    display: flex;
    gap: 5px;
  }
  
  /* Jeder Button wird in einem Container verpackt */
  .buttonContainer {
    position: relative;
    flex: 1;

    height: 40px;
    width: 40px;
  }
  
  .resetContainer {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  /* Gemeinsames Button-Styling */
  .button {
    height: 40px;
    width: 40px;
    display:flex;
    align-items: center;
    justify-content: center;


    padding: 6px 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .button:hover {
    background-color: #e0e0e0;
    transform: scale(1.02);
  }
  
  /* Dropdown: absolut positioniert relativ zum Container des Buttons */
  .dropdown {
    position: absolute;
    top: 100%; /* direkt unter dem Button */
    left: 0px;
    width: 100%; /* genau so breit wie der Button */
    width: 85px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 10;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-out;
  }
  .dropdown2 {
    position: absolute;
    top: 100%; /* direkt unter dem Button */
    left: 0px;
    width: 100%; /* genau so breit wie der Button */
    width: 85px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 10;
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-out;
  }
  
  /* Offen: transformiert zu normaler Größe */
  .open {
    transform: scaleY(1);
  }
  
  .list {
    list-style: none;
    margin: 0;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .listItem {
    padding: 6px 10px;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .listItem:hover {
    background-color: #eaeaea;
    transform: scale(1.02);
  }
  
  .active {
    background-color: #333;
    color: #fff;
    border-color: #333;
  }
  
@media(max-width:800px){
    

  .dropdown {
    position: absolute;

    left: -50px;
  }
  .dropdown2 {
    position: absolute;
    left: -4px;
    
  }


}