.container{
    width: 100%;


}


@media(max-width:1100px){

}

@media(max-width:800px){

}