.container{

    margin:20px;
    width: calc(100% - 40px - 20px);
    padding: 10px;


}




@media(max-width: 900px){
    .container{
        margin:6px;
    width: calc(100% - 12px - 20px);

    }


}