.container {
  display: inline-block;
  position: relative;

  font-size: 30px;
  font-weight:bold;

  margin-bottom: 7px;
}

/* Darstellung des Benutzernamens */
.usernameDisplay {
  position: relative;
  padding: 5px;
}

/* Overlay für das Edit-Icon, das nur beim Hover erscheint */
.overlay {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
}

.usernameDisplay:hover .overlay {
  opacity: 1;
}

/* Das Input-Feld wird so gestylt, dass es wie normaler Text aussieht */
.input {
  padding: 5px;
  border: none;
  background: transparent;
  font-size: 30px;

}



@media(max-width: 900px){
.container {
  font-size: 22px;
}
.input {
  font-size: 22px;
}

}